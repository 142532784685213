import type {HeaderElement} from '~/types/LDictionary';
import {
    mdiAccountTie,
    mdiAccountTieOutline,
    mdiBookOpen,
    mdiBookOpenOutline,
    mdiBriefcaseAccountOutline,
    mdiCalculatorVariant,
    mdiCalculatorVariantOutline,
    mdiFileSyncOutline,
    mdiHomeCityOutline,
    mdiMedalOutline,
    mdiPercent,
    mdiPercentOutline,
    mdiScriptTextOutline,
    mdiSpeedometer,
    mdiStoreMarkerOutline,
    mdiWalletOutline,
    mdiUmbrellaOutline, mdiFlagCheckered
} from '@mdi/js';
import {MORTGAGE_OFFERS_RANKING_URL} from '~/service/constants';
import {RouteLocationRaw, useRouter} from 'vue-router';
import {useTypedRouter} from '~/.router';

export default function useNavbar(customHeaders?: HeaderElement[]) {
    const {getGoToProps,} = useGoTo();
    const {currentRoute,} = useRouter();
    const {routes,} = useTypedRouter();

    const lendiHeaders: HeaderElement[] = [
        {
            name: 'Kredyty',
            value: `/${routes.kredytyHipoteczne}`,
            icon: mdiPercent,
            iconOutline: mdiPercentOutline,
            children: [
                {
                    name: 'Kredyty hipoteczne',
                    value: `/${routes.kredytyHipoteczne}`,
                    icon: mdiHomeCityOutline,
                },
                {
                    name: 'Kredyty gotówkowe',
                    value: `/${routes.kredytyGotowkowe}`,
                    icon: mdiWalletOutline,
                },
            ],
        },
        {
            name: 'Kalkulatory',
            value: `/${routes.kalkulatorZdolnosciKredytowej}`,
            icon: mdiCalculatorVariant,
            iconOutline: mdiCalculatorVariantOutline,
            children: [
                {
                    name: 'Kalkulator zdolności kredytowej',
                    value: `/${routes.kalkulatorZdolnosciKredytowej}`,
                    icon: mdiSpeedometer,
                },
                {
                    name: 'Kalkulator Kredyt na Start',
                    value: `/kredyt-na-start`,
                    icon: mdiFlagCheckered,
                },
                {
                    name: 'Kalkulator Bezpieczny Kredyt 2%',
                    value: `/bezpieczny-kredyt`,
                    icon: mdiUmbrellaOutline,
                },
                {
                    name: 'Kalkulator zmiany oprocentowania kredytu hipotecznego',
                    value: `/${routes.kalkulatorZmianyOprocentowaniaKredytuHipotecznego}`,
                    icon: mdiFileSyncOutline,
                },
                {
                    name: 'Ranking kredytów hipotecznych',
                    value: MORTGAGE_OFFERS_RANKING_URL,
                    icon: mdiMedalOutline,
                },
            ],
        },
        {
            name: 'Eksperci',
            value: `/eksperci-finansowi`,
            icon: mdiAccountTie,
            iconOutline: mdiAccountTieOutline,
            children: [
                {
                    name: 'Eksperci finansowi',
                    value: `/eksperci-finansowi`,
                    icon: mdiBriefcaseAccountOutline,
                },
                {
                    name: 'Placówki',
                    value: `/${routes.placowki}`,
                    icon: mdiStoreMarkerOutline,
                    attributes: {
                        rel: 'nofollow',
                    },
                },
            ],
        },
        {
            name: 'Porady',
            value: 'https://www.lendi.pl/blog/',
            icon: mdiBookOpen,
            iconOutline: mdiBookOpenOutline,
        },
    ];

    const headers: HeaderElement[] = customHeaders ?? lendiHeaders;
    const dropdownsExpandState = reactive<boolean[]>(headers.map(() => false));
    const toggleDropdown = (index: number) => {
        dropdownsExpandState.forEach((state, i, array) => {
            if (i !== index) {
                array[i] = false;
            } else {
                array[i] = !array[i];
            }
        });
    };

    let timer;
    const expandDropdown = (index: number) => {
        clearTimeout(timer);
        dropdownsExpandState.forEach((state, i, array) => {
            array[i] = i === index;
        });
    };
    const hideDropdown = (index: number) => {
        timer = setTimeout(() => (dropdownsExpandState[index] = false), 500);
    };

    const isDropdownExpanded = (dropdownIndex: number): boolean => dropdownsExpandState[dropdownIndex];
    const isAnyDropdownExpanded = computed<boolean>(() => dropdownsExpandState.some((state) => state));
    const goToProps = (header: HeaderElement): { href: string } | { to: RouteLocationRaw } => {
        const routingProps = header.children
            ? undefined
            : getGoToProps(header.value, currentRoute.value.query);
        return {
            ...routingProps,
            ...(header.attributes || {}),
        };
    };

    return {
        headers,
        goToProps,
        dropdownsExpandState,
        toggleDropdown,
        expandDropdown,
        hideDropdown,
        isDropdownExpanded,
        isAnyDropdownExpanded,
    };
}
