<template>
  <v-app :theme="theme">
    <v-theme-provider v-if="(!hideNavbar && !hideCC) || (slots.navbar && slots.navbar())" theme="dark" with-background>
      <slot name="navbar">
        <nav-header v-if="!hideNavbar && !hideCC"
                :headers="navbarHeaders"
                :hide-menu-items="hideMenuItems"
                :hidePromoBanner="hidePromoBanner">
          <template #logo>
            <slot name="navbarLogo"/>
          </template>
          <template #navbarAppend>
            <slot name="navbarAppend">
              <a
                  v-if="!hideConsultationBox && !isLendiget"
                  class="rounded-bl-[40px] !p-4 -mt-4 -mr-4 surface-onDarkBackground"
                  :href="`tel:${LENDI_PHONE}`"
                  :data-cy="$testids.NAVBAR.PHONE_BTN">
                <v-row
                    class="!m-0 items-center">
                  <v-col
                      class="!p-0 flex max-md:order-last md:order-0">
                    <nuxt-img
                        alt="consultation-agent"
                        src="/imgs/consult@3x.webp"
                        class="w-[48px] h-[48px]"
                        width="96"
                        height="96"
                    />
                  </v-col>
                  <v-col
                      class="!py-0 px-3 whitespace-nowrap max-md:text-right md:order-last max-md:order-0">
                    <p class="body-2 text-tertiary">Bezpłatna konsultacja</p>
                    <p class="headline-5 text-tertiary">{{ LENDI_PHONE }}</p>
                  </v-col>
                </v-row>
              </a>
            </slot>
          </template>
        </nav-header>
      </slot>
      <BaseSnackbar/>
    </v-theme-provider>
    <!--CLS hack, vuetify nadpisuje klasy paddingu takie jak pt-5-->
    <v-main :style="{'padding-top': hideNavbar ? 0 : '80px'}" class="flex flex-col h-full">
      <v-container fluid class="!p-0">
        <slot/>
      </v-container>
      <slot name="footer-extra-text"/>
      <nav-footer v-if="!hideFooter"
        :hide-sections="hideMenuItems || hideNavbar"
        :data-cy="$testids.FOOTER.IT"
        class="mt-auto"
      />
    </v-main>
  </v-app>
</template>

<script setup lang="ts">
import {LENDI_PHONE} from '~/service/constants';
import type {LDictionaryWithIcon} from '~/types/LDictionary';
import type {ThemeType} from '~/models/ThemeType';
import {ThemeEnum} from "~/enums/ThemeType";
import {useConsultationQuery} from "~/composables/useConsultationQuery";

type Props = {
  title?: string;
  metaDescription?: string;
  hideNavbar?: boolean,
  hideMenuItems?: boolean,
  hideFooter?: boolean,
  hidePromoBanner?: boolean,
  hideConsultationBox?: boolean,
  theme?: ThemeType,
  navbarHeaders?: LDictionaryWithIcon,
  canonicalRoutePath?: string,
};
const props = withDefaults(defineProps<Props>(), {
  hideNavbar: false,
  hideFooter: false,
  theme: ThemeEnum.DARK,
});

const {query,} = useQuery<{utm_owner: number, cc: string}>();
const {isLendiget} = useConsultationQuery();
const hideCC = computed<boolean>(() => query.cc === 'false' || isLendiget);
const routePath = (useRoute())?.path ?? '';
const basePath = useEnv().appBaseUrl ?? '';
const slots = useSlots();

const hideCanonical = computed(() => {
  return (
      ['/termin-konsultacji', '/umow-konsultacje', '/formularz'].some(path =>
          routePath.includes(path) || (props.canonicalRoutePath && props.canonicalRoutePath.includes(path))
      ) ||
      (
          (routePath === '/' ||
              ['/eksperci-finansowi', '/o-nas', '/rodzinny-kredyt-mieszkaniowy', '/kredyty-hipoteczne', '/kalkulator-zdolnosci-kredytowej', '/bezpieczny-kredyt', '/kalkulator-zmiany-oprocentowania-kredytu-hipotecznego', '/kredyt-na-start', '/kredyty-gotowkowe', '/agenci-ubezpieczeniowi', '/eksperci-finansowi', '/kredyt-hipoteczny'].some(path =>
                  routePath.includes(path)
              )) && Object.keys(query).length > 0
      )
  );

});


useHead({
  title: props.title,
  meta: props.metaDescription ? [
    {
      name: 'description',
      content: props.metaDescription,
    },
  ] : undefined,
  link: hideCanonical.value ? [] : [
    {
      rel: 'canonical',
      href: basePath + (props.canonicalRoutePath || routePath),
    },
  ],
});

</script>

<style lang="scss">
@import '@/assets/styles/vuetify/base/breakpoints/index';

html {
  scroll-padding-top: 150px;
}

.v-application {
  --top-navbar-height: 64px;
  --bottom-navbar-height: 0px;
  overflow: visible;
  &.v-theme--dark {
    color: #FFFFFF;
    background-color: rgb(var(--v-theme-background));
  }
}

::-webkit-scrollbar {
  width: 8px;
  max-width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #FFF;
  box-shadow: inset 1px 1px 2px fpShadow(.1);
  -webkit-box-shadow: inset 1px 1px 2px fpShadow(.1);
}

::-webkit-scrollbar-thumb {
  background: #AAA;
}

::-webkit-scrollbar-thumb:active {
  background: #888;
  box-shadow: inset 1px 1px 2px fpShadow(.3);
  -webkit-box-shadow: inset 1px 1px 2px fpShadow(.3);
}

body {
  * {
    scrollbar-width: thin;
  }
}

.glow-shadow {
  --shadow-blur: 35px;
  --shadow-spread: 0px;
  --shadow-color: rgba(var(--v-theme-primary), 0.8);
}

.glow {
  animation: glow-btn-animation 4s cubic-bezier(0.5, 0.6, 0.6, 0.5) infinite;
  transition: box-shadow 0.5s;
}

@keyframes glow-btn-animation {
  0% {
    box-shadow: -11px -7px var(--shadow-blur) var(--shadow-spread) var(--shadow-color),
    2px 7px var(--shadow-blur) var(--shadow-spread) var(--shadow-color);
  }
  25% {
    box-shadow: -5px -4px var(--shadow-blur) var(--shadow-spread) var(--shadow-color),
    2px 18px var(--shadow-blur) var(--shadow-spread) var(--shadow-color);
  }
  50% {
    box-shadow: -13px 17px var(--shadow-blur) var(--shadow-spread) var(--shadow-color),
    9px 18px var(--shadow-blur) var(--shadow-spread) var(--shadow-color);
  }
  75% {
    box-shadow: -21px 0px var(--shadow-blur) var(--shadow-spread) var(--shadow-color),
    17px 10px var(--shadow-blur) var(--shadow-spread) var(--shadow-color);
  }
  90% {
    box-shadow: -15px -2px var(--shadow-blur) var(--shadow-spread) var(--shadow-color),
    10px 8px var(--shadow-blur) var(--shadow-spread) var(--shadow-color);
  }
  100% {
    box-shadow: -11px -7px var(--shadow-blur) var(--shadow-spread) var(--shadow-color),
    2px 7px var(--shadow-blur) var(--shadow-spread) var(--shadow-color);
  }
}

.v-carousel__controls {

  .v-btn {
    height: 16px;
    width: 16px;
    color: rgba(var(--v-theme-on-surface), var(--v-disabled-opacity));

    .v-icon__svg {
      height: 6px;
      width: 6px
    }
  }

  .v-btn--selected {
    color: rgba(var(--v-theme-on-surface), var(--v-high-emphasis-opacity))
  }
}

.v-card {
  @apply rounded-lg border shadow-none;
}

.surface-onDarkBackground {
  @include tablet {
    background-color: transparent;
  }
  background-color: rgba(var(--v-theme-surfaceColor), var(--v-border-opacity));
}
</style>
