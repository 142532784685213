<template>
  <v-card class="!fixed md:top-[68px] max-md:bottom-0 max-md:w-full max-md:left-0 dropdown" theme="light">
    <v-card-title class="py-4 md:hidden">
      <p class="headline-5 text-left">{{ header.name }}</p>
      <v-btn
        :icon="mdiClose"
        variant="flat"
        class="absolute right-1 top-1"
        tabindex="0"
      />
    </v-card-title>
    <v-list class="py-0" tabindex="-1" :aria-label="header.name">
      <template
          v-for="(subItem, index) in header.children" :key="`sub-item-${index}`">
          <v-list-item
              class="text-left w-full sub-item"
              height="56"
              v-bind="goToProps(subItem)"
              :prepend-icon="subItem.icon"
              :append-icon="mdiChevronRight"
              :title="subItem.name"
              tabindex="0"
              role="option"
          />
      </template>
    </v-list>
    <v-divider class="mx-4"/>
    <v-card-actions>
      <v-row class="items-center">
        <v-col cols="auto" class="p-4">
          <v-avatar size="44px">
            <nuxt-img height="88px" width="88px" class="w-[44px] h-[44px]" src="/imgs/eksperci/expert.webp" alt=""/>
          </v-avatar>
        </v-col>
        <v-col class="px-0 text-left">
          <p class="headline-6">Bezpłatna konsultacja</p>
          <p class="body-2 text-medium-emphasis">Skorzystaj z pomocy eksperta</p>
        </v-col>
        <v-col cols="auto" class="p-4">
          <l-btn rel="nofollow" size="small" type="secondary" @click="goToTerminKonsultacji" :data-cy="$testids.HEADER.MENU_ARRANGE_CONSULTATION">
            Umów
          </l-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts" setup>
import {mdiChevronRight, mdiClose} from '@mdi/js';
import {useTypedRouter} from '~/.router';
import useNavbar from '~/service/useNavbar';

const props = defineProps<{
  header: any,
}>();

const emits = defineEmits<{
  (event: 'click:close'): void
}>();

const {routes,} = useTypedRouter();

const goToTerminKonsultacji = () =>{
  useGoTo().goTo({
    name: routes.terminKonsultacji,
    query: {
      ...useQuery().query,
      utm_channel: 'meeting_widget_menu',
    },
  });
}
const {goToProps,} = useNavbar();

</script>

<style scoped lang="scss">
:deep(.sub-item) {
  @apply relative;

  &:not(:last-child)::before {
    @apply absolute bottom-0 h-px left-4 right-4;
    content: '';
    background-color: rgba(var(--v-border-color), var(--v-border-opacity));
  }
}
</style>
